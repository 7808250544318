<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <Header />
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 ">
            <MediaIcon />
          </div>
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto margin-top-icon-sm"
          >
            <b-card-title class="mb-1">
              OTP 🔒
            </b-card-title>

            <!-- form -->
            <validation-observer ref="forgotPasswordForm">
              <b-form class="auth-forgot-password-form mt-2">
                <!-- username -->
                <b-form-group
                  label="OTP Code"
                  label-for="forgot-password-otp"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="otpCode"
                    vid="otpCode"
                    rules="required"
                  >
                    <b-form-input
                      id="forgot-password-otp"
                      v-model="otpCode"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-password-otp"
                      placeholder="Ex: 123456"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="button"
                  variant="primary"
                  block
                  :disabled="isDisabled"
                  @click="submitOtp"
                >
                  Verify OTP
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link
                :disabled="isLoading"
              >
                <feather-icon icon="ChevronLeftIcon" /> Back to login
              </b-link>
            </p>
          </b-col>
        </div>
      </b-col>
      <!-- /Forgot password-->
      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton, BAlert,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import Header from '@/@core/components/landding-page/Header.vue'
import MediaIcon from '@/@core/components/landding-page/MediaIcon.vue'
import Footer from '@/@core/components/landding-page/Footer.vue'
import CoverImage from '@/@core/components/landding-page/CoverImage.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    // VuexyLogo,
    BRow,
    BCol,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    Header,
    Footer,
    MediaIcon,
    CoverImage,
  },
  data() {
    return {
      isLoading: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      required,
      email,
      isDisabled: true,
    }
  },
  computed: {
    otpCode: {
      get() {
        return this.$store.state['forgot-password'].verifyOtp.otpCode
      },
      set(value) {
        this.$store.commit('forgot-password/setOtpCode', value)
      },
    },
  },
  watch: {
    otpCode(val) {
      if (val === '') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
  },
  methods: {
    ...mapActions('forgot-password', ['submitOtp']),
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
